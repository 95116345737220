/** 
 * A Date converter for timezone issues. Also fixes date string issues with flatpicker selector. 
*@example Sample input: new Date(dateTime)
*@param {Date} 
*@returns timezoneincluded 
 */
export const GetLocalDate = function (dateObj) {
  const date = new Date();
  const dateTime = (new Date(dateObj)).getTime();
  const convertedDate = new Date(dateTime - date.getTimezoneOffset() * 60 * 1000)
  return convertedDate;
}


/**
 *Parameter must contain "datasource" as js object.
 * @example Sample input: {datasource:null}
 * @param {Object} {datasource:[]}
 * @returns status string and context object
 */
export const GetDataStatus = function (data) {
  if (!data) {
    return { response_status: "pending", context: null };
  } else if (
    data.datasource == null ||
    data.datasource.length == 0 ||
    Object.keys(data.datasource).length == 0
  ) {
    if (data.isError) {
      return { response_status: "error", context: data.context };
    }
    return { response_status: "no_data", context: data.context };
  } else if (data.datasource) {
    return { response_status: "success", context: data.context };
  }
};
/**
 * If any of the statuses in the list is "success" then it returns 'success',
 * If any of the statuses in the list is pending then it returns 'pending',
 * rest of the combinations returns 'error'
 * @example Input: ["success","success","error"] returns "success"
 * @param {Array<String>}
 * @returns {"success"|"pending"|"error"}
 */
export const GetGroupStatus = function (arr) {
  if (arr.some(option => option === "success")) {
    return "success";
  } else if (arr.some(option => option === "pending")) {
    return "pending";
  } else if (arr.every(option => option === "error")) {
    return "error";
  } else {
    return "no_data";
  }
};
