import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useCalendarEventHandler(props, clearForm, emit) {

  const isStoppageVisible = ref(false);
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    isStoppageVisible.value = false
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------

  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const jobOptions = ref([]);
  const stoppageTypeOptions = ref([]);
  const deviceOptions = computed(() => {
    return store.getters["calendar/GetDeviceOptions"]
  })
  let prevDeviceId = 0;
  watch(
    eventLocal,
    (newVal) => {
      if (newVal.deviceId !== prevDeviceId && props.isEventHandlerSidebarActive) {
        store.dispatch("calendar/fetchJobEffortFields", newVal.deviceId).then(data => {
          stoppageTypeOptions.value = data.stoppageTypes;
          jobOptions.value = data.jobFieldDtos
          prevDeviceId = newVal.deviceId;
        })
      }
    },
    { deep: true }
  );


  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */

  /* eslint-enable global-require */

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,
    deviceOptions,
    stoppageTypeOptions,
    jobOptions,
    isStoppageVisible,
    // UI

    onSubmit,
  }
}
